import React, { Fragment, useEffect,useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import QuantitySelector from "../QuantitySelector";
import getFormattedPrice from "@assets/js/get-formatted-price";
import getFormattedDeliveryTime from "@assets/js/get-formatted-delivery-time"
import formatPriceToInternational from "@assets/js/get-gtm-formatted-price";
import shallow from "zustand/shallow";
import useBasketStore from "@store/basket";
import useStockStore from "@store/stock";
import { useUniqueMaterialIsUnavailable } from "@store/pdp";
import { placeholderReplace, log } from "@assets/js/utils";
import { dataLayerCommercePush } from "@assets/js/gtm";
import classnames from "classnames";

import useGlobalStore, { useCommerceMarket, useDeliveryTextsFallback } from "@store/global";

import "./mini-basket-product.scss";

const MiniBasketProduct = ({
  basketId,
  Price,
  // PriceExVat,
  Total,
  // TotalExVat,
  TotalDiscount,
  ModelName,
  ModelTypeModelDefinitionName,
  ModelTypeProductSeriesName,
  OrderLineId,
  UniqueMaterialId,
  UniqueMaterialReservationExpired,
  MinimumQuantity,
  // FullFriendlyName,
  FriendlyName,
  ProductImageUrl,
  ProductUrl,
  ModelGroupName,
  Materials,
  Quantity,
  ItemType,
  SkuVariant,
  IsSkuWithConfiguration,
  DesignerName,
  ModelTypeGroup,
  ModelTypeProductType,
  ConfiguratorModelTypeDefinitionName,
  VisualSize,
  // Vat,
  currency = "DKK",
}) => {
  const { t } = useTranslation();

  const setQuantity = useBasketStore((state) => state.setQuantity);
  const setRemoveLine = useBasketStore((state) => state.removeLine);

  const [, setUniqueMaterialIsUnavailable] = useUniqueMaterialIsUnavailable();

  const [commerceMarket] = useCommerceMarket();
  const [language, sc_lang] = useGlobalStore((state) => [state.language, state.sc_lang], shallow);
  const [deliveryTextsFallback] = useDeliveryTextsFallback();

  const stockList = useStockStore((state) => state.stockList);
  const fetchStockData = useStockStore((state) => state.fetchStockData);

  useEffect(() => {
    if (!stockList[FriendlyName]) {
      fetchStockData(commerceMarket, FriendlyName);
    }
  }, [FriendlyName, commerceMarket, fetchStockData, stockList]);

  const texts = {
    model: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Model"),
    remove: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Remove"),
    change: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Change"),
    edit: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Edit"),
    variants: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Variants"),
    estDelivery: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_EstimatedDelivery"
    ),
    noDelivery: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_NoDelivery"
    ),
    lineDiscount: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_LineDiscount"
    ),
    selectedUniqueMaterial: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_SelectedUniqueMaterial"),
    uniqueMaterialReservationNotice: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_UniqueMaterialReservationNotice"),
    uniqueMaterialReservationNoticeDateDelimiter: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_UniqueMaterialReservationNoticeDateDelimiter"),
  };

  const removeProduct = (payload, quantity) => {
    setRemoveLine({
      ...payload,
      currency: currency,
      gtm: Object.assign(
        {},
        {
          name: ModelGroupName,
          id: FriendlyName,
          price: formatPriceToInternational(getFormattedPrice(
            sc_lang,
            commerceMarket,
            null,
            Price,
            false  
          )),
          brand: DesignerName,
          category: `${ModelTypeGroup}/${ModelTypeProductType}`,
          variant: ConfiguratorModelTypeDefinitionName,
          quantity: quantity > 0 ? quantity : 1
        },
        ...Materials?.map((mat, index) => ({
          [`dimension${index + 1}`]: mat.Id,
        }))
      ),
    });
    setUniqueMaterialIsUnavailable(false);
  };

  const maxQuantity = () => {
    let isSkuProduct = ItemType === 1;
    if(!isSkuProduct){
      return 0;
    }
    var minimumQuantity = MinimumQuantity;
    let stockQty = 0;
    if (stockList && stockList[FriendlyName] && isSkuProduct) {
      stockQty = stockList[FriendlyName]?.Stock ?? 0;
    }

    stockQty = stockQty-(stockQty%minimumQuantity);
    return stockQty;
  }

  const changeQuantity = (value, oldValue = 0) => {
    
    if (value >= 1) {
      setQuantityLocked(true);
      setQuantity({
        OrderLineId,
        basketId,
        Quantity: value,
        OldQuantity: oldValue
      }, quantityErrorHandler, quantitySuccessHandler );
    } else {
      removeProduct({
        OrderLineId,
        basketId,
      }, value);
    }
  };
  const [quantityLocked, setQuantityLocked] = useState(!!UniqueMaterialId);
  
  const quantityErrorHandler = () => {
    setQuantityLocked(false);
  };
 
  const quantitySuccessHandler = (newQuantity, oldQuantity) => {  
    if (oldQuantity > newQuantity) {
      dataLayerCommercePush("removeFromCart", {
        currencyCode: currency,
        remove: {
          products: [
            Object.assign(
              {},
              {
                name: ModelGroupName,
                id: FriendlyName,
                price: formatPriceToInternational(getFormattedPrice(
                  sc_lang,
                  commerceMarket,
                  null,
                  Price,
                  false
                )),
                brand: DesignerName,
                category: `${ModelTypeGroup}/${ModelTypeProductType}`,
                variant: ConfiguratorModelTypeDefinitionName,
                quantity: oldQuantity - newQuantity
              },
              ...Materials?.map((mat, index) => ({
                [`dimension${index + 1}`]: mat.Id,
              }))
            )
          ]
        } 
      });
    } else {
      dataLayerCommercePush("addToCart", {
        currencyCode: currency,
        add: {
          products: [
            Object.assign(
            {},
            {
              name: ModelGroupName,
              id: FriendlyName,
              price: formatPriceToInternational(getFormattedPrice(
                sc_lang,
                commerceMarket,
                null,
                Price,
                false
              )),
              brand: DesignerName,
              category: `${ModelTypeGroup}/${ModelTypeProductType}`,
              variant: ConfiguratorModelTypeDefinitionName,
              quantity: newQuantity - oldQuantity
            },
            ...Materials?.map((mat, index) => ({
              [`dimension${index + 1}`]: mat.Id,
            }))
          )]
        } 
      });
    }

    setQuantityLocked(false);
  };

  const uniqueMaterialReservationExpired = () => {
    if (UniqueMaterialReservationExpired) {
      let date = new Date(UniqueMaterialReservationExpired),
        options = { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" };
      return date.toLocaleDateString(
        `${language}-${commerceMarket.toUpperCase()}`,
        options
      ).replace(', ',` ${texts.uniqueMaterialReservationNoticeDateDelimiter} `);
    }
    return null;
  }

  const getDeliveryAndReturnText = (FriendlyName) => {
    let fallbackText = texts.noDelivery;
    if (commerceMarket && deliveryTextsFallback) {
      fallbackText = deliveryTextsFallback[commerceMarket];
      if (!fallbackText) {
        fallbackText = deliveryTextsFallback["dk"];
      }
    }
    return getFormattedDeliveryTime(stockList, FriendlyName, fallbackText, language, commerceMarket.toUpperCase());
  }

  const productImageClassList = classnames({
    "product-image": true,
    [`product-image--${VisualSize?.toLowerCase()}`]: VisualSize
  });

  return (
    <div className="mini-basket-product">
      <span className="mini-basket-product__image">
        <div className={productImageClassList}>
          {ProductUrl ?
            (<a href={ProductUrl}><img src={ProductImageUrl} alt={ModelTypeModelDefinitionName || ModelName} /></a>) :
            (<img src={ProductImageUrl} alt={ModelTypeModelDefinitionName || ModelName} />)
          }
        </div>
      </span>

      <div className="mini-basket-product__links">
        <button
          className="mini-basket-product__link"
          onClick={() => removeProduct({ OrderLineId, basketId }, Quantity)}
        >
          <span>{texts.remove}</span>
        </button>
        {ProductUrl && <a className="mini-basket-product__link" href={ProductUrl}>
          <span>{texts.change}</span>
        </a>}
      </div>
    
      <div className="mini-basket-product__text">
        <h3>{ModelTypeProductSeriesName || ModelGroupName}</h3>
        <p>
          <strong>{texts.model}:</strong> {ModelTypeModelDefinitionName || ModelName}
        </p>
        <p>
          {ItemType === 1 && !IsSkuWithConfiguration? (
            <>            
              <strong>{texts.variants}:{" "}</strong>
              <span>{SkuVariant?.Name}</span>
            </>
          ) : (
            <>
              {Materials.map((item, index) => (
                <Fragment key={`MiniBasketProductMaterials${index}`}>
                  <strong>{item.ConfigurationTypeName}:</strong>{" "}
                  {item.MaterialSetName && item.MaterialSetName.length > 0 ? (
                    <span key={`prodMat${index}`}>{item.MaterialSetName + " / " + item.Name}</span>
                  ) : (
                    <span key={`prodMat${index}`}>{item.Name}</span>
                  )}
                  <br />
                </Fragment>
              ))}
            </>)}
            {UniqueMaterialId ? (
              <>
                <strong>{texts.selectedUniqueMaterial}:{" "}</strong>
                <span>{UniqueMaterialId}</span>
              </>
            ) : ""} 
        </p>
        <p>
          <strong>{texts.estDelivery}</strong>:<br />
          {getDeliveryAndReturnText(FriendlyName)}
        </p>
        {uniqueMaterialReservationExpired() && (
          <p className="mini-basket-product__unique-material-notice">
            {placeholderReplace(texts.uniqueMaterialReservationNotice, { uniqueMaterialReservationExpired })}
          </p>
        )}
      </div>

      <div className="mini-basket-product__quantity">
        <QuantitySelector
          isLocked={quantityLocked}
          defaultValue={Quantity}
          changeHandler={changeQuantity}
          step={MinimumQuantity}
          minValue={MinimumQuantity}
          maxValue={maxQuantity()}
          disableInputChange ={MinimumQuantity > 1}
          showQuantityValidationModal = {true}
        />
      </div>

      <div className="mini-basket-product__prices">
        {/*fields.value.priceOriginal && <h5>{fields.value.priceOriginal}</h5>*/}
        {Quantity === 1 ? (
          ""
        ) : (
          <p>
            {Quantity}&nbsp;&times;&nbsp;
            {getFormattedPrice(language, commerceMarket, currency, Price)}
          </p>
        )}
        {TotalDiscount === 0 ? (
          <h4>
            {getFormattedPrice(language, commerceMarket, currency, Total)}
          </h4>
        ) : (
          <>
            <h5>
              {getFormattedPrice(
                language,
                commerceMarket,
                currency,
                Total + TotalDiscount
              )}
            </h5>
            <p>
              {texts.lineDiscount} -{" "}
              {getFormattedPrice(
                language,
                commerceMarket,
                currency,
                TotalDiscount
              )}
            </p>
            <h4>
              {getFormattedPrice(language, commerceMarket, currency, Total)}
            </h4>
          </>
        )}
      </div>
    </div>
  );
};

MiniBasketProduct.propTypes = {
  basketId: PropTypes.string.isRequired,
  OrderLineId: PropTypes.number.isRequired,
  FullFriendlyName: PropTypes.string.isRequired,
  Price: PropTypes.number,
  ModelGroupName: PropTypes.string,
  Materials: PropTypes.array,
  Quantity: PropTypes.number,
  currency: PropTypes.string,
};

export default MiniBasketProduct;
